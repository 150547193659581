import React, { useContext, useEffect, useState } from "react";
import Topbar from "./Topbar";
import Logo from "./Logo";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FooterBar from "./FooterBar";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "../../../context/Loader/Loader";
import WhatsappLogo from "./WhatsappLogo";
import LoaderContext from "../../../context/Loader/LoaderContext";
const FrontMain = () => {
    const [isLoaded, setIsLoaded] = useState(false); // Start with false
    const { loaderCheck } = useContext(LoaderContext);
    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })
        const handleLoad = () => {
            setTimeout(() => {
              setIsLoaded(true); // Open popup after 5s delay
            }, 1500); // 5000ms = 5 seconds
          };
    
        if (document.readyState === "complete") {
          // If already loaded, open popup immediately
          handleLoad();
        } else {
          window.addEventListener("load", handleLoad);
        }
    
        return () => {
          window.removeEventListener("load", handleLoad);
        };
      }, []);

    return (
        <>
            <Loader />
            <Topbar />
            <Logo />
            <Navbar />
            <div style={{marginBottom:window.location.pathname.includes("urban-gardens") ? "0px" : "55px"}}>
            <Outlet />
            </div>
            <Footer />
            <FooterBar />
            < ToastContainer />
            {
                isLoaded && !loaderCheck && <WhatsappLogo/>
            }
        </>
    );
}

export default React.memo(FrontMain) // Memoize the FrontMain