import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/Auth/AuthContext';
import axiosApiSigninInstance from '../../hooks/axiosLogin';
import axiosPrivate from "../../hooks/axiosPrivate";
import AlertContext from '../../context/Alert/AlertContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Auth.css';
import { showErrorToast } from '../../Toster';
import CartCountContext from '../../context/CartCount/CartCountContext';

const SignIn = () => {
  const { user, setUser } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [validation, setValidation] = useState(true)
  const navigate = useNavigate();

  const { cartCountCheck, setCartCountCheck, setCartCount } =
    useContext(CartCountContext);

  const loginSchma = Yup.object({
    mobile: Yup.string().required('Mobile is Required'),
    otp: Yup.string().required(' OTP is Required'),
  });

  const loginForm = {
    mobile: '',
    otp: '',
  };

  const otpSchma = Yup.object({
    mobile: Yup.string().required('Mobile is Required'),
  });

  const otpForm = {
    mobile: '',
  };

  const addToCart = async (userId, cartItems) => {
    let userLogin = localStorage.getItem("userData");
    console.log("Cart Items", cartItems);
    
    await cartItems?.map(async (item, index) =>{
      let object = {
      product_id: item?.product_id,
      variant_id: item?.variant_id,
      quantity: item?.quantity,
      user_id: userId,
    };

    await axiosPrivate
        .post("/carts/create", object)
        .then((res) => {
         localStorage.removeItem("cart_items")
        })
        .catch((error) => {
          
        });
    })

    setCartCountCheck(!cartCountCheck);
    
  };

  const { values, handleBlur, handleChange, handleSubmit, setFieldValue ,errors, touched } =
    useFormik({
      initialValues: validation ? otpForm : loginForm,
      validationSchema: validation ? otpSchma : loginSchma,
      onSubmit: (data, values) => {
        validation ? 

        axiosApiSigninInstance
          .post('generate-otp', data)
          .then((res) => {
            setValidation(false)
            
          })

          .catch((err) => {
            showErrorToast(
              err?.response?.data?.message ||
                err?.response?.data?.errors[0]?.msg
            );
          })

          : 
          
          axiosApiSigninInstance
          .post('login', data)
          .then(async(res) => {
            localStorage.setItem('userData', JSON.stringify(res.data));

            const cartItems = JSON.parse(localStorage.getItem("cart_items"))
            if(cartItems){
              await addToCart(res?.data?.userId, cartItems)
            }
            setUser(res.data);
            if (res.data.role == 'client') {
              navigate('/user-dashboard');
            } else {
              navigate('/admin');
            }
          })

          .catch((err) => {
            showErrorToast(
              err?.response?.data?.message ||
                err?.response?.data?.errors[0]?.msg
            );
          });
      },
    });

  useEffect(() => {
    if (user.token && user.role !== 'client') {
      navigate('/admin', { replace: true });
    }
  }, []);

  

  const onSubmit = async (data) => {
    await axiosApiSigninInstance
      .post('login', data)
      .then((res) => {
        setUser(res.data);
        localStorage.setItem('userData', JSON.stringify(res.data));
        setTimeout(() => {
          if (res.data.role == 'client') {
            navigate('/');
          } else {
            navigate('/admin');
          }
        }, 1000);
      })

      .catch((err) => {
        setAlertMessage({ message: 'testewew' });
        showErrorToast(
          err?.response?.data?.message || err?.response?.data?.errors[0]?.msg
        );
      });
  };

  // alert
  useEffect(() => {
    if (alertMessage != null) {
      toast.success(alertMessage.message, {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  const numberFilter = (event) => {
    const inputValue = event.target.value;
    const numericValue = parseInt(inputValue.replace(/\D/g, ''), 10);
    setFieldValue(event.target.name, numericValue) 
  };


  return (
    <Fragment>
      <div className='auth_left'>
        <div className='card'>
          <div className='text-center mb-2'>
            <Link className='header-brand' to='/'>
              {/* <i className="fe fe-command brand-logo" /> */}
              <img
                src='./../assets/images/home/logo.webp'
                alt='login'
                loading='lazy'
              />
            </Link>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit}>
              <div className='card-title' style={{textTransform:'none'}}>Login to your account</div>

              <div className='form-group'>
                <label className='form-label'>
                  Mobile<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='mobile'
                  id='mobile'
                  className='form-control'
                  value={values.mobile}
                  onChange={numberFilter}
                  onBlur={handleBlur}
                  placeholder='Mobile'
                  maxLength={10}
                />
                {touched.mobile && errors.mobile ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.mobile}
                  </div>
                ) : null}
              </div>
              {
                 !validation && (
                  <div className='form-group'>
                <label className='form-label'>
                  OTP <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  name='otp'
                  id='otp'
                  className='form-control'
                  value={values.otp}
                  onChange={numberFilter}
                  onBlur={handleBlur}
                  placeholder='OTP'
                  maxLength={6}
                />
                {touched.otp && errors.otp ? (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.otp}
                  </div>
                ) : null}
              </div>
                 )
              }
              
              {
                 validation ? (
                  <div className='form-footer'>
                <input
                  type='submit'
                  className='btn  btn-block submit-button'
                  value='Send OTP'
                />
              </div>
                 )

                 :

                 (
                  <div className='form-footer'>
                <input
                  type='submit'
                  className='btn  btn-block submit-button'
                  value='Login'
                />
              </div>
                 )
              }
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignIn;
