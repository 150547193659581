import React, { useState } from "react";
import styles from "./FaqSection.module.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi"; // Importing arrow icons

const FaqSection = ({product_faqs
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.faqWrapper}>
      <div className={styles.faqLeft}>
        <img
          src="/assets/images/home/FaqRightImage.svg"
          alt="FAQ Illustration"
          className={styles.faqImage}
        />
      </div>
      <div className={styles.faqRight}>
        {product_faqs.map((faq, index) => (
          <div
            key={index}
            className={`${styles.faqItem} ${
              activeIndex === index ? styles.active : ""
            }`}
          >
            <div
              className={styles.faqQuestion}
              onClick={() => toggleAccordion(index)}
            >
              {faq?.question}
              <span className={styles.faqIcon}>
                {activeIndex === index ? <FiChevronUp color="#555555"/> : <FiChevronDown color="#555555"/>}
              </span>
            </div>
            {activeIndex === index && (
              <div className={styles.faqAnswer}>
                <p>
                  {faq?.answer}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
