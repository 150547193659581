import styles from "./inquire.module.css";
import { errorToast } from "../../../src/components/backend/common/Toaster";

export default function EnquireOnWhatsApp({ detail }) {
  const handleWhatsAppClick = () => {

    if (!detail.length) {
      errorToast("Please select at least one item!");
      return;
    }
    const phoneNumber = "9429198147";
    const productDetails = detail
      .map((item) => `Product: ${item.name}, Quantity: ${item.quantity}`)
      .join("\n");

    const message = encodeURIComponent(
      `Hello, I want to inquire about the following products:\n\n${productDetails}`
    );

    // Check if the user is on a mobile device
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
      ? `https://wa.me/${phoneNumber}?text=${message}` // Mobile app
      : `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`; // WhatsApp Web

    window.open(whatsappUrl, "_blank");
  };

  return (
    <span className={styles.watsappBtn} onClick={handleWhatsAppClick}>
      Inquire on WhatsApp
    </span>
  );
}
