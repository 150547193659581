import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdEmail, MdAccountCircle } from 'react-icons/md';
import { ImFacebook } from "react-icons/im";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io";
import { TiSocialYoutube } from "react-icons/ti";
import { Link } from 'react-router-dom';
import styles from './Topbar.module.css';


function Topbar() {
    return (
        <div className={styles.topbar}>
            {/* Contact */}
            <section className={styles.contactContainer}>
                <div className={styles.contactDetail}>
                    <p className={styles.tollFree}>Order on: <a href="tel:7574011099"><span>+91 7574011099</span></a></p>
                    <Link to="/"> <BsThreeDotsVertical className={styles.setting} /></Link>
                    <a href="mailto:sales@minfert.in">
                        <div className={`${styles.mailContainer} ${styles.link}`}>
                            <MdEmail className={styles.mailIcon} />
                            <p className={styles.mail}>sales@minfert.in</p>
                        </div>
                    </a>
                </div>
            </section>
                
            
            {/* Account */}
            <section className={`${styles.accountContainer} ${styles.secondSection}`}>
                <div className={styles.social}>
                    <Link to="https://www.facebook.com/minfert/" target='_blank'>
                        <div className={styles.socialLink}>
                            <ImFacebook />
                        </div>
                    </Link>

                    <Link to="https://www.instagram.com/minfert.in/" target='_blank'>
                        <div className={styles.socialLink}>
                            <AiFillInstagram />
                        </div>
                    </Link>

                    <Link to="https://twitter.com/Minfert_Organic" target='_blank'>
                        <div className={styles.socialLink}>
                            <FaTwitter />
                        </div>
                    </Link>

                    <Link to="https://in.linkedin.com/company/minfert" target='_blank'>
                        <div className={styles.socialLink}>
                            <IoLogoLinkedin />
                        </div>
                    </Link>

                    <Link to="https://www.youtube.com/@minfert" target='_blank'>
                        <div className={styles.socialLink}>
                            <TiSocialYoutube />
                        </div>
                    </Link>
                </div>
            </section>
        </div>
    )
}

export default React.memo(Topbar) // Memoize the Topbar
