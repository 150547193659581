import LoaderContext from "./LoaderContext";
import { useState, useEffect } from "react";

const LoaderState = (props) => {
    const [loaderCheck, setLoaderCheck] = useState(true); // ✅ Start with true on refresh

    useEffect(() => {
        // ✅ Simulate a loading process (e.g., 2 seconds)
        const timer = setTimeout(() => {
            setLoaderCheck(false); // Hide loader after delay
        }, 2000);

        return () => clearTimeout(timer); // Cleanup
    }, []);

    return (
        <LoaderContext.Provider value={{ loaderCheck, setLoaderCheck }}>
            {props.children}
        </LoaderContext.Provider>
    );
};

export default LoaderState;
