import React, { useState } from "react";
import styles from "./WhatsappLogo.module.css";
import { BsWhatsapp } from "react-icons/bs";
import { IoCaretDownSharp, IoCloseSharp } from "react-icons/io5";

const WhatsappLogo = () => {
  const [visible, setVisible] = useState(true);

  const createWhatsAppURL = (phoneNumber) => {
    const phoneWithoutSpecialChars = phoneNumber.replace(/[^\d]/g, "");
    return `https://api.whatsapp.com/send?phone=${phoneWithoutSpecialChars}`;
  };

  const phoneNumber = "9429198147";

  const handleClose = () => {
    // First, immediately hide the container by adding the hide class
    document.getElementById("whatsappContainer").classList.add(styles.hide);

    // Then, after a slight delay, setVisible to false
    setTimeout(() => {
      setVisible(false);
    }, 300); // Adjust the time based on your animation duration
  };

  const handleAnimationEnd = () => {
    setVisible(false);
  };

  return (
    <div className={styles.whatsAppMainContainer}>
      {
        visible && (
          <div id="whatsappContainer" className={`${visible ? styles.show : ""}`} onAnimationEnd={handleAnimationEnd}>
        <div className={styles.chatWithUs}>Chat With Us</div>
        <div className={styles.downArrow}>
          <IoCaretDownSharp color="#FF5100" />
        </div>
        <div className={styles.closeIcon} onClick={handleClose}>
          <IoCloseSharp />
        </div>
      </div>
        )
      }
      {/* WhatsApp Floating Button (Always Visible) */}
      <a
        href={createWhatsAppURL(phoneNumber)}
        className={styles.float}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/assets/images/home/WhatsappLogo.svg" alt="WhatsApp" className={styles["prevent-color-change"]}/>
        {/* <BsWhatsapp className={styles["prevent-color-change"]} /> */}
      </a>
    </div>
  );
};

export default React.memo(WhatsappLogo);
