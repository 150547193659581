import React, { useEffect, useState } from "react";
import styles from "./ExplorePopup.module.css";
import { GrClose } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';

function ExplorePopup({ isOpen, onClose }) {
  const [isVisible, setIsVisible] = useState(isOpen);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      document.body.style.overflow = "hidden"; 
    } else {
      setTimeout(() => setIsVisible(false), 300); 
      document.body.style.overflow = "auto"; 
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleAnimationEnd = () => {
    if (!isOpen) {
      setIsVisible(false);
    }
  };

  return (
    isVisible && (
      <div className={`${styles.popupMainContainer} ${isOpen ? styles.fadeIn : styles.fadeOut}`} onAnimationEnd={handleAnimationEnd}>
        <div className={styles.popupModelContainer}>
          <img className={styles.popupLeftImage} src={"/assets/images/home/minfert-modal.jpg"} alt="minfert modal" />
          <div className={styles.popupRightImage}>
            <div className={styles.closeIconMainContainer}>
              <GrClose className={styles.closeIcon} onClick={onClose} />
            </div>
            <div className={styles.rightMsgContainer}>
              <div className={styles.rightMsgFirstSection}>
                Revive Your<br />Urban Garden Naturally!
              </div>
              <div className={styles.rightMsgSecondSection}>
                From Balcony to Bloom,<br />We've Got You Covered
              </div>
              <div className={styles.exploreButtonMainContainer}>
                <span className={styles.exploreButton} onClick={() => navigate('/urban-gardens')}>Explore</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default React.memo(ExplorePopup);
