import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TbGardenCart } from "react-icons/tb";
import { FaBookmark } from "react-icons/fa";
import styles from "./ProductDetail.module.css";
import { BsCurrencyRupee } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";
// import { Carousel } from 'primereact/carousel';
import axiosPrivate from "./../../../hooks/axiosPrivate";
import CartCountContext from "../../../context/CartCount/CartCountContext";
import { errorToast, successToast } from "../../backend/common/Toaster";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { Helmet } from "react-helmet";
import { IoIosStar } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { AiOutlinePlus } from "react-icons/ai";
import StarRating from "../StarRating/StarRating";
import EnquireOnWatsapp from "../../inquire-on-watsapp";

function ProductDetail({ product }) {
  const slug = useParams();
  const navigate = useNavigate();
  const imgRef = useRef(null);
  const videoRef = useRef(null);

  const [selectedVarient, setSelectedVarient] = useState(null);
  const [product_variant, setProduct_variant] = useState({});
  const { setLoaderCheck } = useContext(LoaderContext);
  const { cartCountCheck, setCartCountCheck } = useContext(CartCountContext);
  const [imageChangeByVariant, setImageChange] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [wishList, setWishList] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [imageContainerHeight, setImageContainerHeight] = useState("auto");
  const [imageContainerWidth, setImageContainerWidth] = useState("auto");
  const [selectedFrequentProducts, setSelectedFrequentProducts] = useState([]);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  let action = localStorage.getItem("action");

  const [firstOrder, setFirstOrder] = useState(
    localStorage.getItem("first_order")
  );

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  const handleMouseMove = (e) => {
    const { left, top, width, height } = imgRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    imgRef.current.style.transformOrigin = `${x}% ${y}%`;
  };

  useEffect(() => {
    if (Object.keys(product)?.length > 0) {
      if (slug?.id) {
        onVariantChange(slug?.id);
      } else {
        onVariantChange(product?.product_variants[0]?.id);
      }
    }
  }, [product]);

  const onVariantChange = (id) => {
    setSelectedVarient(id);
    let product_variant = product?.product_variants?.filter(
      (res) => res?.id == id
    );

    if (product_variant.length > 0) {
      setImageChange(
        process.env.REACT_APP_COMMON_FILE_URL +
          "product-variants/" +
          product_variant[0].varinat_iamges[0].image
      );
    }

    if (product_variant?.length > 0) {
      let obj = product_variant[0];
      setProduct_variant(obj);
    }
  };

  const addToCart = (actionType) => {
    let object = {
      product_id: product.id,
      variant_id: selectedVarient,
      quantity: quantity,
    };

    if (userData && action === "login") {
      setLoaderCheck(true);
      axiosPrivate
        .post("/carts/create", object)
        .then(async (res) => {
          setLoaderCheck(false);
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
          await setCartCountCheck(!cartCountCheck);
          // debugger;
          if (actionType !== "buynow") {
            successToast("Item Added in Cart!");
          }
          // setAlertMessage({ message: "Item Added in Cart!" });
          if (actionType == "buynow") {
            navigate("/cart");
          }
        })
        .catch((error) => {
          setLoaderCheck(false);
          errorToast("Something Went Wrong!");
          // setAlertMessage({ message: "Something Went Wrong!" });
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        });
    } else {
      object["product_name"] = product?.name;
      object["rate"] = product_variant?.regular_price;
      //  object["base_unit"] = productDetail.product_variants[0].base_unit;
      object["variant"] = product_variant?.variant;
      object["short_description"] = product?.short_description;
      if (product?.length > 0 || Object.keys(product)?.length > 0) {
        object["image"] = product_variant?.varinat_iamges[0]?.image;
      }
      let cart_items = localStorage.getItem("cart_items");
      let cart_array = [];
      cart_array.push(object);
      if (!cart_items) {
        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      } else {
        let cart_array = JSON.parse(cart_items);
        let boolean_cart = false;
        cart_array?.forEach((element) => {
          if (object.variant_id == element.variant_id) {
            boolean_cart = true;
            element.quantity = element.quantity + 1;
          }
        });

        if (!boolean_cart) {
          cart_array.push(object);
        }
        localStorage.setItem("cart_items", JSON.stringify(cart_array));
      }
      window.scroll({
        top: 0,
        behavior: "smooth",
      });

      if (actionType !== "buynow") {
        successToast("Item Added in Cart!");
      }
      setCartCountCheck(!cartCountCheck);
      if (actionType == "buynow") {
        navigate("/cart");
      }
    }
  };

  const handleCheckboxChange = (productId, price) => {
    setSelectedFrequentProducts((prevSelected) => {
      let updatedSelected;
      if (prevSelected.includes(productId)) {
        // Remove product if already selected
        updatedSelected = prevSelected.filter((id) => id !== productId);
      } else {
        // Add product if not selected
        updatedSelected = [...prevSelected, productId];
      }

      // Calculate new total price based on selected products
      const total = product?.frequent_products
        ?.filter((item) => updatedSelected.includes(item.frequent_product_id))
        .reduce((sum, item) => sum + Number(item.sale_price), 0);

      setAdditionalPrice(total);
      return updatedSelected;
    });
  };

  const addBothToCart = async () => {
    if (!selectedFrequentProducts.length) {
      errorToast("Please select at least one item!");
      return;
    }

    const selectedItems = product?.frequent_products?.filter((item) =>
      selectedFrequentProducts.includes(item.frequent_product_id)
    );

    setLoaderCheck(true);

    try {
      for (let i = 0; i < selectedItems.length; i++) {
        const item = selectedItems[i];

        let object = {
          product_id: item.frequent_product_id,
          variant_id: item.variant_id,
          quantity: 1,
          userId: userData?.userId || null,
        };

        if (userData) {
          // API call for logged-in users
          await axiosPrivate.post("/carts/create", object);

          // Show success toast **only after the last item is added**
          if (i === selectedItems.length - 1) {
            setCartCountCheck(!cartCountCheck);
            successToast(
              selectedItems.length > 1
                ? "Items added to Cart!"
                : "Item added to Cart!"
            );
          }
        } else {
          // Local storage fallback
          object["product_name"] = item?.name;
          object["rate"] = item?.sale_price;
          object["variant"] = item?.variant_id;
          object["image"] = item?.images[0]?.image;

          let cartItems = JSON.parse(localStorage.getItem("cart_items")) || [];

          let existingItemIndex = cartItems.findIndex(
            (cartItem) => cartItem.variant_id === object.variant_id
          );

          if (existingItemIndex !== -1) {
            // Item already exists, increase quantity
            cartItems[existingItemIndex].quantity += 1;
          } else {
            // New item, add to cart
            cartItems.push(object);
          }

          localStorage.setItem("cart_items", JSON.stringify(cartItems));

          // Show success toast **only after the last item is added**
          if (i === selectedItems.length - 1) {
            setCartCountCheck(!cartCountCheck);
            successToast(
              selectedItems.length > 1
                ? "Items added to Cart!"
                : "Item added to Cart!"
            );
          }
        }
      }
      navigate("/cart");
    } catch (error) {
      errorToast("Something Went Wrong!");
    } finally {
      setLoaderCheck(false);
    }
  };

  const getDetails = () => {
    const selectedItems = product?.frequent_products?.filter((item) =>
      selectedFrequentProducts.includes(item.frequent_product_id)
    );

    const result = selectedItems.map((item) => ({
      name: item.name,
      quantity: 1,
    }));

    return result;
  };

  const getWishList = () => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("wishlist/get", options)
      .then((res) => {
        res?.data?.data?.map((item) => {
          if (item?.product_id === product?.id) {
            setWishList(true);
          }
        });
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getWishList();
  }, [product?.id]);

  if (userData) {
    var { userId } = userData;
  }

  const productSaveHandler = (e, res) => {
    if (!userData) {
      errorToast(
        "First, you need to login, to add a product to your wishlist."
      );
    }
    const object = {
      user_id: userId,
      variant_id: res?.product_variants[0]?.id,
      product_id: res?.id,
      ...res,
    };
    axiosPrivate
      .post("wishlist/create", object)
      .then((res) => {
        if (res.data?.code == 201) {
          successToast("Product added to Wishlist");
          getWishList();
        } else {
          errorToast("First you have to login!");
        }
      })
      .catch((error) => {
        errorToast("First you have to login!");
        errorToast(error?.response?.data?.message);
      });
  };

  const handleImageClick = (image) => {
    setSelectedImage(
      process.env.REACT_APP_COMMON_FILE_URL + "product-variants/" + image
    );
  };

  const updateMediaSize = () => {
    if (imgRef.current && imgRef.current.clientWidth) {
      setImageContainerHeight(`${imgRef.current.clientHeight}px`);
      setImageContainerWidth(`${imgRef.current.clientWidth}px`);
    } else if (videoRef.current && imgRef.current.clientWidth) {
      setImageContainerHeight(`${videoRef.current.clientHeight}px`);
      setImageContainerWidth(`${videoRef.current.clientWidth}px`);
    }
  };

  useEffect(() => {
    if (showImage && imgRef.current) {
      const timeout = setTimeout(updateMediaSize, 300); // Small delay to ensure media loads
      return () => clearTimeout(timeout);
    }
  }, [showImage, imgRef.current]);

  useEffect(() => {
    if (selectedImage) {
      setShowImage(selectedImage);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (imageChangeByVariant) {
      setShowImage(imageChangeByVariant);
    }
  }, [imageChangeByVariant]);

  const liquidVariant = product?.product_variants?.filter((res) => {
    return (
      res?.variant?.toLowerCase().includes("ltr") ||
      res?.variant?.toLowerCase().includes("ml")
    );
  });

  const kgVariant = product?.product_variants?.filter((res) => {
    return (
      res?.variant?.toLowerCase().includes("kg") ||
      res?.variant?.toLowerCase().includes("grams")
    );
  });

  return (
    <>
      <Helmet>
        <title>{product?.meta_title}</title>
        <meta name="description" content={product?.meta_description} />
        <meta name="keywords" content={product?.meta_keywords} />
        <meta name="tags" content={product?.tags} />
        <meta property="og:title" content={product?.og_title} />
        <meta property="og:description" content={product?.og_description} />
        <meta property="og:image" content={product?.og_image} />
        <meta property="og:url" content={product?.og_image} />
        <meta name="twitter:title" content={product?.meta_title} />
        <meta name="twitter:description" content={product?.meta_description} />
        <meta name="twitter:image" content={product?.og_image} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className={styles.productDetailPage}>
        <div className={styles.productDetail}>
          <div className={styles.productImages}>
            <div
              className={styles.productMoreImages}
              style={{
                maxHeight: imageContainerHeight,
                maxWidth: imageContainerWidth,
              }}
            >
              {product?.product_variants?.length > 0 &&
                product_variant?.varinat_iamges?.map((res, index) => {
                  const fileUrl =
                    process.env.REACT_APP_COMMON_FILE_URL +
                    "product-variants/" +
                    res?.image;
                  const isVideo = res?.image?.toLowerCase().endsWith(".mp4"); // Check if file is a video

                  return (
                    <div
                      className={styles.moreImage}
                      style={{ border: isVideo && "none" }}
                      key={index}
                    >
                      {isVideo ? (
                        <video
                          disablePictureInPicture
                          onClick={() => handleImageClick(res?.image)}
                          width="100%"
                          height="auto"
                        >
                          <source src={fileUrl} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={fileUrl}
                          alt="variantImage"
                          loading="lazy"
                          onClick={() => handleImageClick(res?.image)}
                        />
                      )}
                    </div>
                  );
                })}
            </div>

            <div
              className={styles.mainImage}
              onMouseMove={
                showImage?.toLowerCase().endsWith(".mp4")
                  ? undefined
                  : handleMouseMove
              }
            >
              {showImage &&
                (showImage.toLowerCase().endsWith(".mp4") ? (
                  <video
                    className={styles.showVideo}
                    ref={videoRef}
                    width="100%"
                    height="auto"
                    autoPlay
                    loop
                    muted
                    controls
                    disablePictureInPicture
                  >
                    <source src={showImage} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={showImage}
                    ref={imgRef}
                    className={styles.hoverZoom}
                    alt=""
                    loading="lazy"
                  />
                ))}
            </div>
          </div>

          <div className={styles.detail}>
            {/* heading */}
            <div className={styles.aboutProduct}>
              <div className={styles.description}>
                <h1 className={styles.productName}>{product?.name}</h1>
                <a
                  href="#productReviews"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("productReviews")
                      ?.scrollIntoView({ behavior: "smooth" });
                  }}
                  className={styles.ratingContainer}
                >
                  <div className={styles.ratingStar}>
                    <span>{product?.star_rating}</span>
                    <IoIosStar />
                  </div>
                  <div className={styles.ratingReview}>
                    {product?.number_of_rating}
                  </div>
                </a>
                <p>{product.forms}</p>
              </div>
            </div>

            <div className={styles.priceMainContainer}>
              <div className={styles.priceContainer}>
                {/* price */}
                <div className={`${styles.productPrice} ${styles.productName}`}>
                  <BsCurrencyRupee />
                  {product_variant?.regular_price}
                </div>
                <div className={styles.discountPrice}>
                  {product_variant?.discount ? (
                    <>
                      <BsCurrencyRupee /> {product_variant.discount}
                    </>
                  ) : null}
                </div>
              </div>
              <div>
                {/* stock */}
                <div className={styles.stock}>
                  <p>In stock</p>
                  <div className={styles.varientContainer}>
                    <div className={`${styles.productVarient} productVarient`}>
                      <div className="card flex justify-content-center mb-0">
                        <Dropdown
                          value={selectedVarient}
                          optionValue="id"
                          onChange={(e) => onVariantChange(e.value)}
                          options={product?.product_variants}
                          optionLabel="variant"
                          placeholder="Varient"
                          className="w-full md:w-14rem"
                        />
                      </div>
                      <div className={styles.counterContainer}>
                        <FaMinus
                          className={styles.cursorPointer}
                          onClick={() =>
                            setQuantity((prev) => Math.max(1, prev - 1))
                          }
                          size={14}
                        />

                        <span className={styles.countValue}>{quantity}</span>
                        <FaPlus
                          className={styles.cursorPointer}
                          onClick={() => setQuantity((prev) => prev + 1)}
                          size={14}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttonsContainer}>
                {/* <span
                  className={styles.cardInactiveButton}
                  onClick={() => addToCart()}
                >
                  Add to Cart
                </span> */}
                <EnquireOnWatsapp
                  detail={[{ name: product?.name, quantity: quantity }]}
                />
                {/* <span
                  className={styles.cardActiveButton}
                  onClick={() => addToCart("buynow")}
                >
                  Buy Now
                </span> */}
              </div>
            </div>
            {/* product description */}
            <div className={styles.description}>
              <p className={styles.descriptionContent}>
                <strong>Category:</strong>
                <span>{product.category_name}</span>
              </p>

              {/* <p><strong>Suitable:</strong>{product.short_description}</p> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: product?.short_description,
                }}
              />
            </div>
          </div>
        </div>
        {product?.frequent_products?.length > 0 && (
          <div className={styles.frequentlyBoughtContainer}>
            <h2 className={styles.frequentlyBoughtContainerTitle}>
              Frequently Bought Together
            </h2>
            <div className={styles.frequentlyBoughtContainerMainSection}>
              <div className={styles.frequentlyBoughtCardSection}>
                {product?.frequent_products?.map((item, index) => {
                  return (
                    <>
                      <div className={styles.frequentlyBoughtCard} key={index}>
                        <div
                          style={{ objectFit: "cover", position: "relative" }}
                        >
                          <img
                            // src={item?.images[0]?.image}
                            src={`${
                              process.env.REACT_APP_COMMON_FILE_URL +
                              "products/" +
                              item?.images[0]?.image
                            }`}
                            alt="frequentlyBought"
                            loading="lazy"
                            className={styles.frequentlyBoughtCardImage}
                          />
                          <input
                            type="checkbox"
                            checked={selectedFrequentProducts.includes(
                              item.frequent_product_id
                            )}
                            onChange={() =>
                              handleCheckboxChange(
                                item.frequent_product_id,
                                item.sale_price
                              )
                            }
                            className={styles.frequentlyBoughtCardSelection}
                          />
                        </div>
                        <div>
                          <p className={styles.frequentlyBoughtProductName}>
                            {item?.name}
                          </p>
                          <div
                            className={
                              styles.frequentlyBoughtCardPriceRatingContainer
                            }
                          >
                            <div className={styles.ratingContainer}>
                              <StarRating value={item?.star_rating} />
                            </div>
                            <span className={styles.frequentlyBoughtCardPrice}>
                              {"₹" + item.sale_price}
                            </span>
                          </div>
                        </div>
                      </div>
                      {product?.frequent_products.length - 1 !== index && (
                        <AiOutlinePlus size={34} className={styles.plusIcon} />
                      )}
                    </>
                  );
                })}
              </div>
              <div className={styles.frequentlyBoughtContainerTotalSection}>
                <div className={styles.frequentlyBoughtContainerTotal}>
                  <h3 className={styles.frequentlyBoughtProductName}>
                    {" "}
                    Total Additional Amount:
                  </h3>
                  <h3 className={styles.frequentlyBoughtProductsTotalPrice}>
                    {" "}
                    ₹ {additionalPrice.toFixed(2)}
                  </h3>
                </div>
                <div className={styles.frequentlyBoughtCardTotalButtonSection}>
                  {/* <span
                    className={`${styles.frequentlyBoughtCardTotalSectionButton} ${styles.cardActiveButton}`}
                    onClick={() => addBothToCart()}
                  >
                    {selectedFrequentProducts?.length > 1
                      ? "Add Items to Cart"
                      : "Add Item to Cart"}
                  </span> */}
                  <EnquireOnWatsapp detail={getDetails()} />
                  {/* <span
                    className={`${styles.frequentlyBoughtCardTotalSectionButton} ${styles.cardInactiveButton}`}
                    onClick={() => addToCart("buynow")}
                  >
                    Skip & Proceed
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(ProductDetail); // Memoize the ProductDetail;
