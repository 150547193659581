import React from "react";
import styles from "./RecomendedProduct.module.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import StarRating from "../StarRating/StarRating";

function RecommendedProduct({ Details }) {
  const productCount = Details?.related_products?.length;

  const settings = {
    dots: false, // Enable dots for pagination
    infinite: productCount > 4 ? true : false, // Infinite scrolling
    slidesToShow: 4, // Show 3 slides at a time
    speed: 500, // Transition speed
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Set the interval for autoplay
    nextArrow: <FaChevronRight size={30} color="#666666" />,
    prevArrow: <FaChevronLeft size={30} color="#666666" />,
    responsive: [
      {
        breakpoint: 1200, // Large screens
        settings: {
          infinite: productCount > 2 ? true : false, // Infinite scrolling
          slidesToShow: 2, // Show 3 slides at a time
        },
      },

      {
        breakpoint: 576, // Mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const initialValues = {
    mainHeading: "Recommended Products",
  };

  return (
    <div className={styles.recommendationMainContainer}>
      <div className={styles.handpickedRecommendation}>
        Handpicked Recommendations
      </div>
      <div className={styles.exclusiveProductsWrapper}>
        <Slider {...settings}>
          {Details?.related_products?.map((item, index) => (
            <div key={index} className={styles.exclusiveProductCard}>
              <img
                src={
                  process.env.REACT_APP_COMMON_FILE_URL +
                  `products/${item?.product_image[0]?.image}`
                }
                alt="ExclusiveProduct"
                loading="lazy"
                className={styles.exclusiveProductImage}
              />
              <div className={styles.productDetailsMainContent}>
                <div className={styles.productDetailsContent}>
                  <div className={styles.productVariantName}>
                    <div className={styles.productName}>{item?.name}</div>
                  </div>
                  <div className={styles.starMargin}>
                    <StarRating value={item?.star_rating} />
                  </div>
                </div>
                <div className={styles.productCategory}>
                  {item?.category_name}
                </div>
                <div className={styles.buttonsContainer}>
                  {/* <a
                      href={item?.product_pdf}
                      className={styles.cardInactiveButton}
                    >
                      Know More
                    </a> */}
                  <a
                    href={`/product-detail/${item?.slug}`}
                    className={styles.cardActiveButton}
                  >
                    <span>Shop Now</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default React.memo(RecommendedProduct) || RecommendedProduct;
