import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import DataTable2 from '../../../DataTable2';
import TopNavBar from '../../layouts/TopNavBar';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import { errorToast, successToast } from '../../common/Toaster';
import LoaderContext from '../../../../context/Loader/LoaderContext';
import AuthContext from '../../../../context/Auth/AuthContext';

const Products = () => {
    const [productState, setProductState] = useState([]);
    const { setLoaderCheck } = useContext(LoaderContext);
    const { authPermissions } = useContext(AuthContext);
    const headers = [
        // { name: 'S NO', field: 'sr_no', sortable: true, classKey: '' },
        { name: 'NAME', field: 'name', sortable: true, classKey: '' },
        { name: 'CATEGORY NAME', field: 'category_name', sortable: true, classKey: '' },
        { name: 'SLUG', field: 'slug', sortable: true, classKey: '' },
        { name: 'ACTION', field: 'action', classKey: '' },
    ];
    const searchItems = ['name'];

    //Get Product List
    useEffect(() => {
        const options = {
            headers: { 'content-type': 'application/json' },
        };
        setLoaderCheck(true);
        axiosPrivate
            .get('products', options)
            .then((products) => {
                setLoaderCheck(false);
                const productsData = products.data.data.map((value, index) => {
                    let buttons = [];
                    if (authPermissions.includes('Product-update'))
                        buttons.push(
                            <Link
                                key="editButton##1"
                                type="button"
                                to={`/admin/products/edit/${value.id}`}
                                className="btn btn-icon"
                                title="Edit"
                            >
                                <i className="fa fa-edit"></i>
                            </Link>
                        );
                    if (authPermissions.includes('Product-delete'))
                        buttons.push(
                            <button
                                key="deleteButton##1"
                                type="button"
                                data-id={value.id}
                                onClick={() => productDeleteHandler(value.id)}
                                className="btn btn-icon js-sweetalert"
                                title="Delete"
                            >
                                <i className="fa fa-trash-o text-danger"></i>
                            </button>
                        );
                    value['action'] = buttons.length > 0 ? buttons : '-';
                    value['sr_no'] = index + 1;
                    return value;
                });

               
                if (authPermissions.includes('Product-read')) {
                    setProductState(productsData);
                } else {
                    setProductState([]);
                }
            })
            .catch((error) => {
                setLoaderCheck(false);
                errorToast(error?.response?.data?.message);
            });
    }, []);

    //Function to delete a product
    const productDeleteHandler = async (id) => {
        setLoaderCheck(true);
        axiosPrivate
            .delete(`products/${id}`)
            .then(async (response) => {
                setLoaderCheck(false);
                if (response.data.code === 200) {
                    setProductState([]);
                    successToast('Product deleted successfully');
                    await refreshTable();
                }
            })
            .catch((error) => {
                setLoaderCheck(false);
                errorToast(error?.response?.data?.message);
            });
    };

    //Function to refresh data after every api call
    const refreshTable = () => {
        return new Promise((resolve, reject) => {
            const options = {
                headers: { 'content-type': 'application/json' },
            };
            setLoaderCheck(true);
            axiosPrivate
                .get('products', options)
                .then((products) => {
                    setLoaderCheck(false);
                    const productsData = products.data.data.map((value, key) => {
                        let buttons = [];
                        if (authPermissions.includes('Product-update'))
                            buttons.push(
                                <button
                                    key="editButton##1"
                                    type="button"
                                    className="btn btn-icon"
                                    title="Edit"
                                >
                                    <i className="fa fa-edit"></i>
                                </button>
                            );
                        if (authPermissions.includes('Product-delete'))
                            buttons.push(
                                <button
                                    key="deleteButton##1"
                                    type="button"
                                    data-id={value.id}
                                    onClick={() => productDeleteHandler(value.id)}
                                    className="btn btn-icon js-sweetalert"
                                    title="Delete"
                                >
                                    <i className="fa fa-trash-o text-danger"></i>
                                </button>
                            );
                        value['action'] = buttons.length > 0 ? buttons : '-';
                        return value;
                    });
                    if (authPermissions.includes('Product-read')) {
                        setProductState(productsData);
                    } else {
                        setProductState([]);
                    }
                    resolve(true);
                })
                .catch((error) => {
                    setLoaderCheck(false);
                    reject(error);
                    errorToast(error?.response?.data?.message);
                });
        });
    };
    const addLink = authPermissions.includes('Product-create') === true ? '/admin/products/add' : '';

    return (
        <>
            <TopNavBar links={{ list: '/admin/products', add: addLink }} />
            <div className="section-body">
                <div className="container-fluid">
                    <div className="tab-content">
                        <div
                            className="tab-pane fade show active"
                            id="product-list"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div className="card-header">
                                    {/* <h3 className="card-title">PRODUCT LIST</h3> */}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        {
                                            <DataTable2
                                                lists={productState}
                                                headers={headers}
                                                searchItems={searchItems}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Products;
