import React from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";

function StarRating({ value}) {
  return (
    <div>
        <Stack spacing={1}>
      <Rating
        name="half-rating-read"
        value={Number(value)}
        precision={0.5}
        readOnly
        sx={{
          fontSize: { xs: 16, md: 18 },
          "& .MuiRating-iconEmpty": {
            color: "#FFB907", // Different color for read-only rating
            
          },
        }}
      />
    </Stack>
    </div>
  );
}

export default StarRating;
