import "./App.css";
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import AlertState from "./context/Alert/AlertState";
import AuthState from "./context/Auth/AuthState";
import Main from "./components/backend/layouts/Main";
import AuthMain from "./components/backend/layouts/AuthMain";
import DashBoard from "./components/backend/dashboard/DashBoard";
import Users from "./components/backend/masters/users/Users";
import AddUser from "./components/backend/masters/users/AddUser";
import EditUser from "./components/backend/masters/users/EditUser";
import UserPermission from "./components/backend/masters/user_permissions/UserPermission";
import Categories from "./components/backend/masters/categories/Categories";
import AddCategory from "./components/backend/masters/categories/AddCategory";
import EditCategory from "./components/backend/masters/categories/EditCategory";
import SubCategories from "./components/backend/masters/sub_categories/SubCategories";
import AddSubCategory from "./components/backend/masters/sub_categories/AddSubCategory";
import EditSubCategory from "./components/backend/masters/sub_categories/EditSubCategory";
import Products from "./components/backend/masters/products/Products";
import AddProduct from "./components/backend/masters/products/AddProduct";
import ProductVariants from "./components/backend/masters/products/ProductVariants";
import SignUp from "./components/auth/SignUp";
import SignIn from "./components/auth/SignIn";
import Coupon from "./components/backend/masters/coupon/Coupon";
import AddCoupon from "./components/backend/masters/coupon/AddCoupon";
import EditCoupon from "./components/backend/masters/coupon/EditCoupon";
import Tax from "./components/backend/masters/tax/Tax";
import AddTax from "./components/backend/masters/tax/AddTax";
import EditTax from "./components/backend/masters/tax/EditTax";
import Blog from "./components/backend/masters/Blog/Blog";
import AddBlog from "./components/backend/masters/Blog/AddBlog";
import BlogType from "./components/backend/masters/BlogType/BlogType";
import AddBlogType from "./components/backend/masters/BlogType/AddBlogType";
import EditBlogType from "./components/backend/masters/BlogType/EditBlogType";
import FrontMain from "./components/frontend/layout/FrontMain";
//import { ToastContainer } from "react-toastify";
import EditProduct from "./components/backend/masters/products/EditProduct";
//import AboutPageTemplate from "./components/custom-pages-templates/about-template/AboutPageTemplate";
import Orders from "./components/backend/masters/orders/Orders";
import AddOrder from "./components/backend/masters/orders/EditOrders";
import ProductListing from "./components/frontend/ProductListingByCategory/ProductListing";
import ProductDetails from "./components/frontend/productDetail/ProductDetails";
import Checkout from "./components/frontend/Checkout/checkout";
import ProductTemplate from "./components/backend/masters/Product Template/ProductTemplate";
import AddProductTemplate from "./components/backend/masters/Product Template/AddProductTemplate";
import UserDashboard from "./components/frontend/userDashboard/UserDashboard";
import UserProfile from "./components/frontend/userDashboard/personalDetails/PersonalDetails";
import Blogpage from "./components/frontend/pages/Blog";
import Cart from "./components/frontend/Cart/Cart";
import DistributorPage from "./components/frontend/DistributorPage/DistributorPage";
import Menus from "./components/custom-pages-templates/menus/Menus";
import CommonTemplate from "./components/frontend/CommonTemplate";
import AddHomePageTemplate from "./components/custom-pages-templates/home-template/add-home-template";
import AboutPageTemplate from "./components/custom-pages-templates/about-template/AboutPageTemplate";
import HomeTemplate from "./components/custom-pages-templates/home-template/home-template";
import MenuList from "./components/custom-pages-templates/menus/Menus-listing";
import AboutPageListingTemplate from "./components/custom-pages-templates/about-template/AboutPageListingTemplate";
import AddDistributorTemplate from "./components/custom-pages-templates/distributor-template/add-distributorPageTemplate";
import DistributorPageTemplate from "./components/custom-pages-templates/distributor-template/distributorPageTemplate";
import ContactUsPage from "./components/frontend/ContactUs/contact-us";
import ContactListing from "./components/backend/masters/forms/contact/contact";
import BlogDetail from "./components/frontend/blog/BlogDetail";
import Enquiry from "./components/backend/masters/Enquiry/Enquiry";
import BlogComments from "./components/backend/masters/BlogComments/BlogComments";
import EditComments from "./components/backend/masters/BlogComments/EditComments";
import EnquiryView from "./components/backend/masters/Enquiry/EnquiryView";
import DistributorEnquiry from "./components/backend/masters/DistributorEnquiry/DistributorEnquiry";
import DistributorEnquiryView from "./components/backend/masters/DistributorEnquiry/DistributorEnquiryView";
import ReturnOrders from "./components/backend/masters/ReturnOrders/ReturnOrders";
import ViewOrderDetails from "./components/frontend/userDashboard/orders/ViewOrderDetails";
import Gallery from "./components/backend/masters/Gallery/Gallery";
import AddGallery from "./components/backend/masters/Gallery/AddGallery";
import EditGallery from "./components/backend/masters/Gallery/EditGallery";
import ProductGallery from "./components/frontend/gallery/Gallery";
import Thankyou from "./components/frontend/ThankYou/thankyou";
import CareerPage from "./components/frontend/careerPage/CareerPage";
import CommigSoon from "./components/frontend/layout/CommigSoon";
import Career from "./components/backend/masters/careerPage/Career";
import CareerView from "./components/backend/masters/careerPage/CareerView";
import ReturnOrdersView from "./components/backend/masters/ReturnOrders/ReturnOrderView";
import { ToastContainer } from "react-toastify";
import CartCountState from "./context/CartCount/CartCountState";
import LoaderState from "./context/Loader/LoaderState";
import Userprofile from "./components/backend/masters/userProfile/UserProfile";
import AuditLogs from "./components/backend/masters/AuditLogs/AuditLogs";
import ProductCrop from "./components/frontend/gallery/Crop";
import ViewOrders from "./components/backend/masters/orders/ViewOrders";
import EditOrders from "./components/backend/masters/orders/EditOrders";
import ForgotPassword from "./components/auth/ForgotPassword";
import SubscribedMail from "./components/backend/SubscribedMail/SubscribedMail";
import NotFound from "./components/frontend/NotFound";
import UrbanGardening from "./components/frontend/MarketplaceProducts/UrbanGardening";
import Crops from "./components/frontend/MarketplaceProducts/Crops";
import Nursery from "./components/frontend/MarketplaceProducts/Nursery";
import Forestry from "./components/frontend/MarketplaceProducts/Forestry";
import { LoadScript } from "@react-google-maps/api";
import CropCategories from "./components/backend/masters/crop-categories/CropCategories";
import AddCropCategory from "./components/backend/masters/crop-categories/AddCropCategory";
import Broucher from "./components/frontend/Broucher";
import FarmersTraining from "./components/frontend/gallery/FarmersTraining";
import Testimonial from "./components/frontend/Testimonial/Testimonial";
import Testimonials from "./components/backend/masters/Testimonials/Testimonials";
import EditTestimonials from "./components/backend/masters/Testimonials/EditTestimonials";
import CheckoutState from "./context/Checkout/CheckoutState";
function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthState>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}>
          <AlertState>
            <LoaderState>
              <CartCountState>
                <CheckoutState>
                  <Routes>
                    {/* website routes */}
                    <Route path="/comming-soon" element={<CommigSoon />} />
                    <Route path="" element={<FrontMain />}>
                    <Route path="/" element={<Navigate to="/home" replace />} />
                      <Route path="/:slug" element={<CommonTemplate />} />
                      {/* <Route path="/" element={<CommonTemplate />} /> */}
                      {/* <Route path="/about-us" element={<AboutUsPage/>} /> */}
                      <Route path="/brochure" element={<Broucher />} />
                      <Route path="/testimonial" element={<Testimonial />} />
                      <Route
                        path="products/:slug"
                        element={<ProductListing />}
                      />
                      <Route path="marketplace/crops" element={<Crops />} />
                      <Route
                        path="marketplace/urban-gardening"
                        element={<UrbanGardening />}
                      />
                      <Route path="marketplace/nursery" element={<Nursery />} />
                      <Route
                        path="marketplace/forestry"
                        element={<Forestry />}
                      />

                      <Route
                        path="/product-detail/:slug"
                        element={<ProductDetails />}
                      />

                      <Route
                        path="/product-detail/:slug/:id"
                        element={<ProductDetails />}
                      />

                      <Route path="blog-listing" element={<Blogpage />}></Route>
                      <Route path="blog-detail/:id" element={<BlogDetail />} />
                      <Route
                        path="/product-detail"
                        element={<ProductDetails />}
                      />
                      <Route
                        path="/user-dashboard"
                        element={<UserDashboard />}
                      />
                      <Route
                        path="/order-detail/:id"
                        element={<ViewOrderDetails />}
                      />
                      <Route
                        path="/thank-you-page/:id"
                        element={<Thankyou />}
                      />
                      <Route
                        path="/user-dashboard"
                        element={<UserDashboard />}
                      />
                      <Route
                        path="/distributor-detail"
                        element={<DistributorPage />}
                      />
                      <Route path="/gallery" element={<ProductGallery />} />
                      <Route
                        path="/farmers-training"
                        element={<FarmersTraining />}
                      />
                      <Route path="/crops" element={<ProductCrop />} />
                      <Route path="/cart" element={<Cart />} />
                      <Route path="/checkout" element={<Checkout />} />
                      <Route path="/contact-us" element={<ContactUsPage />} />
                      {/* <Route path=":slug" element={<CommonTemplate />} /> */}
                      <Route path="/" element={<CommonTemplate />} />
                      <Route path="/careers" element={<CareerPage />} />
                    </Route>
                    {/* ecommerce user routes */}
                    <Route element={<Main />}>
                      <Route
                        path="/user-dashboard"
                        element={<UserDashboard />}
                      />
                      <Route
                        path="/order-detail"
                        element={<ViewOrderDetails />}
                      />
                      <Route path="/user-profle" element={<UserProfile />} />
                    </Route>
                    <Route path="auth" element={<AuthMain />}>
                      <Route path="signin" element={<SignIn />} />
                      <Route path="signup" element={<SignUp />} />
                    </Route>
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />

                    {/* admin routes */}
                    <Route path="admin" element={<Main />}>
                      <Route path="" element={<DashBoard />} />
                      <Route path="users">
                        <Route path="" element={<Users />} />
                        <Route path="add" element={<AddUser />} />
                        <Route path="edit/:id" element={<EditUser />} />
                        <Route
                          path="permissions/:userId/:roleId"
                          element={<UserPermission />}
                        />
                      </Route>
                      <Route path="audit-logs" element={<AuditLogs />} />
                      <Route path="userprofile/:id" element={<Userprofile />} />
                      <Route path="career">
                        <Route path="" element={<Career />} />
                        <Route path="view/:id" element={<CareerView />} />
                      </Route>

                      <Route path="categories">
                        <Route path="" element={<Categories />} />
                        <Route path="add" element={<AddCategory />} />
                        <Route path="edit/:id" element={<EditCategory />} />
                      </Route>
                      <Route path="crop-categories">
                        <Route path="" element={<CropCategories />} />
                        <Route path="add" element={<AddCropCategory />} />
                        <Route path="edit/:id" element={<AddCropCategory />} />
                      </Route>
                      <Route path="sub-categories">
                        <Route path="" element={<SubCategories />} />
                        <Route path="add" element={<AddSubCategory />} />
                        <Route path="edit/:id" element={<EditSubCategory />} />
                      </Route>
                      <Route path="enquiry">
                        <Route path="" element={<Enquiry />} />
                        <Route path="view/:id" element={<EnquiryView />} />
                      </Route>
                      <Route path="blogcomments">
                        <Route path="" element={<BlogComments />} />
                        <Route path="edit/:id" element={<EditComments />} />
                      </Route>
                      <Route path="testimonials">
                        <Route path="" element={<Testimonials />} />
                        <Route path="edit/:id" element={<EditTestimonials />} />
                      </Route>

                      <Route
                        path="subscribed-emails"
                        element={<SubscribedMail />}
                      />

                      <Route path="distributorenquiry">
                        <Route path="" element={<DistributorEnquiry />} />
                        <Route
                          path="view/:id"
                          element={<DistributorEnquiryView />}
                        />
                      </Route>

                      <Route path="coupon">
                        <Route path="" element={<Coupon />} />
                        <Route path="add" element={<AddCoupon />} />
                        <Route path="edit/:id" element={<EditCoupon />} />
                      </Route>

                      <Route path="orders">
                        <Route path="" element={<Orders />} />
                        <Route path="view/:id" element={<ViewOrders />} />
                        <Route path="edit/:id" element={<EditOrders />} />
                      </Route>

                      <Route path="returnorders">
                        <Route path="" element={<ReturnOrders />} />
                        <Route path="view/:id" element={<ReturnOrdersView />} />
                      </Route>

                      <Route path="blog">
                        <Route path="" element={<Blog />} />
                        <Route path="add" element={<AddBlog />} />
                        <Route path="edit/:id" element={<AddBlog />} />
                      </Route>

                      <Route path="blogtype">
                        <Route path="" element={<BlogType />} />
                        <Route path="add" element={<AddBlogType />} />
                        <Route path="edit/:id" element={<EditBlogType />} />
                      </Route>

                      <Route path="tax">
                        <Route path="" element={<Tax />} />
                        <Route path="add" element={<AddTax />} />
                        <Route path="edit/:id" element={<EditTax />} />
                      </Route>
                      <Route path="home-template">
                        <Route path="" element={<HomeTemplate />} />
                        <Route path="add" element={<AddHomePageTemplate />} />
                        <Route
                          path="edit/:id"
                          element={<AddHomePageTemplate />}
                        />
                      </Route>

                      <Route path="about-template">
                        <Route path="" element={<AboutPageListingTemplate />} />
                        <Route path="add" element={<AboutPageTemplate />} />
                        <Route
                          path="edit/:id"
                          element={<AboutPageTemplate />}
                        />
                      </Route>

                      <Route path="menus">
                        <Route path="" element={<MenuList />} />
                        <Route path="add" element={<Menus />} />
                        <Route path="edit/:id" element={<Menus />} />
                      </Route>

                      <Route path="distributor">
                        <Route path="" element={<DistributorPageTemplate />} />
                        <Route
                          path="add"
                          element={<AddDistributorTemplate />}
                        />
                        <Route
                          path="edit/:id"
                          element={<AddDistributorTemplate />}
                        />
                      </Route>

                      <Route path="contact-listing">
                        <Route path="" element={<ContactListing />} />
                      </Route>
                      <Route path="distributor-listing">
                        <Route path="" element={<ContactListing />} />
                      </Route>

                      <Route path="products">
                        <Route path="" element={<Products />} />
                        <Route path="add" element={<AddProduct />} />
                        <Route path="edit/:id" element={<EditProduct />} />
                        <Route
                          path="variants/:product_id"
                          element={<ProductVariants />}
                        />
                      </Route>
                      <Route path="ProductTemplate">
                        <Route path="" element={<ProductTemplate />} />
                        <Route path="add" element={<AddProductTemplate />} />
                        <Route
                          path="edit/:id"
                          element={<AddProductTemplate />}
                        />
                      </Route>

                      <Route path="gallery">
                        <Route path="" element={<Gallery />} />
                        <Route path="add" element={<AddGallery />} />
                        <Route path="edit/:id" element={<EditGallery />} />
                      </Route>
                    </Route>

                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </CheckoutState>
              </CartCountState>
            </LoaderState>
          </AlertState>
        </LoadScript>
      </AuthState>
    </BrowserRouter>
  );
}

export default App;
