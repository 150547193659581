import { Fragment, useContext } from "react";
import { Link , useLocation} from "react-router-dom";
import userPng from "../../../assets/images/user.png";
import { GiHamburgerMenu, GiNotebook } from "react-icons/gi";
import {
  MdDashboard,
  MdLocalLibrary,
  MdPhotoLibrary,
  MdSocialDistance,
} from "react-icons/md";
import { FaBookReader, FaSitemap, FaUsers } from "react-icons/fa";
import {
  PiBagSimpleFill,
  PiKeyReturnFill,
  PiNotePencilFill,
} from "react-icons/pi";
import { TiShoppingCart, TiThMenu } from "react-icons/ti";
import {
  BiSolidCoupon,
  BiSolidDuplicate,
  BiSolidPhoneCall,
} from "react-icons/bi";
import { HiDuplicate, HiReceiptTax } from "react-icons/hi";
import { AiFillHome, AiOutlineAudit } from "react-icons/ai";
import mainLogo from "../../../assets/images/faviconNew.png";
import AuthContext from "../../../context/Auth/AuthContext";

const SideBar = () => {
  const { authPermissions } = useContext(AuthContext);
  const location = useLocation();
  const userData = JSON.parse(localStorage?.getItem("userData"));
  const { userId } = userData || "";

  

  return (
    <Fragment>
      <div id="header_top" className="header_top false">
        <div className="container">
          <div className="hleft">
            <Link
              aria-current="page"
              className="header-brand active"
              to="/admin"
            >
              <i>
                <img src={mainLogo} alt="mainlogo" style={{ width: "7vh" }} />
              </i>
            </Link>
            {/* <div className="dropdown">
              <Link className="nav-link icon" to="/admin/page-search">
                <i className="fa fa-search"></i>
              </Link>
            </div> */}
          </div>
          <div className="hright">
            <div className="dropdown">
              <Link to={"userprofile/" + userId}>
                <p className="nav-link user_btn">
                  <img
                    className="avatar"
                    src={userPng}
                    alt="fake_alr"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="User Menu"
                  />
                </p>
              </Link>
              <p className="nav-link icon menu_toggle">
                <i className="fa  fa-align-left"></i>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="left-sidebar" className="sidebar ">
        <h5 className="brand-name">
          <Link to="/">Minfert</Link>
        </h5>
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <div>
            <ul className="metismenu">
              <li>
                <span>
                  <a aria-current="page" className="list-a" href="/events">
                    <GiHamburgerMenu className="mr-3" />
                    Masters
                    <i className="caret-left"></i>
                  </a>
                </span>
                <ul className="collapse collapse collapse collapse collapse collapse collapse collapse collapse collapse">
                <li>
                <span >
                      <Link
                        className="list-a"
                        to="/admin"
                       
                      >
                        <MdDashboard className="mr-2" />
                        Dashboard
                      </Link>
                    </span>
                  </li>
                  {authPermissions.includes("User-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/users">
                          <FaUsers className="mr-2" />
                          Users
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Order-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/orders">
                          <PiNotePencilFill className="mr-2" />
                          Orders
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Return Order-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/returnorders">
                          <PiKeyReturnFill className="mr-2" />
                          Return Orders
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Product-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/products">
                          <TiShoppingCart className="mr-2" />
                          Products
                        </Link>
                      </span>
                    </li>
                  )}

                  {authPermissions.includes("Category-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/categories">
                          <BiSolidDuplicate className="mr-2" />
                          Categories
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Sub Category-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/sub-categories">
                          <HiDuplicate className="mr-2" />
                          Sub Categories
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Enquiry-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/enquiry">
                          <BiSolidPhoneCall className="mr-2" />
                          Enquiries
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Distributor Enquiry-read") && (
                    <li>
                      <span style={{ wordSpacing: "2cm" }}>
                        <Link className="list-a" to="/admin/distributorenquiry">
                          <MdLocalLibrary className="mr-2" />
                          Distributor <span className="mx-4">Enquiries</span>
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Testimonials-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/testimonials">
                          <PiBagSimpleFill className="mr-2" />
                          Testimonials
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Blog Comments-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/blogcomments">
                          <PiBagSimpleFill className="mr-2" />
                          Blog Comments
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Career-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/career">
                          <PiBagSimpleFill className="mr-2" />
                          Career
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Coupon-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/coupon">
                          <BiSolidCoupon className="mr-2" />
                          Coupons
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Tax-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/tax">
                          <HiReceiptTax className="mr-2" />
                          Tax
                        </Link>
                      </span>
                    </li>
                  )}

                  {authPermissions.includes("Blog Category-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/blogtype">
                          <HiDuplicate className="mr-2" />
                          Blog Categories
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Blog-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/blog">
                          <GiNotebook className="mr-2" />
                          Blogs
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Category-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/crop-categories">
                          <BiSolidDuplicate className="mr-2" />
                          Crop Categories
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Gallery-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/gallery">
                          <MdPhotoLibrary className="mr-2" />
                          Gallery
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Tax-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/audit-logs">
                          <AiOutlineAudit className="mr-2" />
                          Audit Logs
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Tax-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/subscribed-emails">
                          <AiOutlineAudit className="mr-2" />
                          Subscribed Emails
                        </Link>
                      </span>
                    </li>
                  )}
                </ul>
              </li>
              <li>
                <span>
                  <a aria-current="page" className="list-a" href="/events">
                    <FaSitemap className="mr-3" />
                    Website
                    <i className="caret-left"></i>
                  </a>
                </span>
                <ul className="collapse collapse collapse collapse collapse">
                  {authPermissions.includes("Home Template-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/home-template">
                          <AiFillHome className="mr-2" />
                          Home Template
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("About Template-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/about-template">
                          <FaBookReader className="mr-2" />
                          About Template
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Distributor Template-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/distributor">
                          <MdSocialDistance className="mr-2" />
                          Distributor Template
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Products Template-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/ProductTemplate">
                          <TiShoppingCart className="mr-2" />
                          Products Template
                        </Link>
                      </span>
                    </li>
                  )}
                  {authPermissions.includes("Menu-read") && (
                    <li>
                      <span>
                        <Link className="list-a" to="/admin/menus">
                          <TiThMenu className="mr-2" />
                          Menus
                        </Link>
                      </span>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default SideBar;
