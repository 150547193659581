import React, { useState, useEffect } from 'react';
import styles from './PlantGrowth.module.css';

function PlantGrowth({ plantValues }) {
  const heading = plantValues?.mainHeading?.split(' ');
  const slicedHeading =
    heading?.length >= 7 ? heading?.slice(0, 8).join(' ') + '...' : heading;
  const [expandedIndex, setExpandedIndex] = useState(null);
  useEffect(() => {
    if(plantValues?.mainContent){
      const mainContent = document.getElementById('mainContent');
    mainContent.innerHTML = plantValues?.mainContent.replace(
      /<a\b(.*?)>/gi,
      `<a $1 class=${styles.link}>`
    );
    }
  }, [plantValues?.mainContent]);

  const showContentHandler = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className={`container ${styles.plantgrowthContainer}`}>
      <div className={styles.mainContainer}>
        <div className={styles.mainHeadingContainer}>
          <h2 className={styles.mainHeading}>
            {slicedHeading && slicedHeading}
            <img
              src={plantValues?.leafImage}
              alt='leaf'
              loading='lazy'
              className={styles.leaf}
            />
          </h2>
        </div>
        <p id='mainContent' className={styles.mainContent}></p>
      </div>
      <div className={styles.boxContainer}>
        {plantValues !== undefined &&
          plantValues?.plantInformation?.map((value, index) => {
            return index % 2 === 0 ? (
              <>
                <div className={`${styles.box} ${styles.boxPaddingFirstAndLastRow}`}>
                  <h3 className={styles.boxHeading}>
                    {value?.content1?.subHeading}
                  </h3>
                  <p className={styles.boxContent} style={{paddingLeft: "0px"}}>
                    {value?.content1?.subContent}
                  </p>
                </div>
                <div className={`${styles.box}`}>
                  <img
                    src={value?.image}
                    alt=''
                    loading='lazy'
                    className={styles.boxImg}
                  />
                </div>
                <div className={`${styles.box} ${styles.boxPaddingFirstAndLastRow}`}>
                  <h3 className={styles.boxHeading}>
                    {value?.content2?.subHeading}
                  </h3>
                  <p className={styles.boxContent} style={{paddingRight:"0px"}}>
                    {value?.content2?.subContent}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className={`${styles.box}`}>
                  <img
                    src={value?.image}
                    alt=''
                    loading='lazy'
                    className={styles.boxImg}
                  />
                </div>
                <div className={`${styles.box} ${styles.boxPaddingCenterRow}`}>
                  <h3 className={styles.boxHeading}>
                    {value?.content?.subHeading}
                  </h3>
                  <p className={styles.boxContent}>
                    {value?.content?.subContent}
                  </p>
                </div>
                <div className={`${styles.box}`}>
                  <img
                    src={value?.image1}
                    alt=''
                    loading='lazy'
                    className={styles.boxImg}
                  />
                </div>
              </>
            );
          })}

        {/* <div className={styles.box}>
                    <h3 className={styles.boxHeading}>Lorem ipsum</h3>
                    <p className={styles.boxContent}>Unlike most fertilizers which dissolve
                        immediately in water, Minfert plant
                        food being in pallet form dissolves
                        slowly and serves as food for the
                        crops, which they can consume little
                        by little every day,like most other living creatures.
                    </p>
                </div>

                <div className={styles.box}>
                    <img src="./assets/images/home/Mask group-2.webp" alt="" loading="lazy" className={styles.boxImg} />
                </div>
                <div className={styles.box}>
                    <h3 className={styles.boxHeading}>Lorem ipsum</h3>
                    <p className={styles.boxContent}>Unlike most fertilizers which dissolve
                        immediately in water, Minfert plant
                        food being in pallet form dissolves
                        slowly and serves as food for the
                        crops, which they can consume little
                        by little every day,like most other living creatures.
                    </p>
                </div> */}
      </div>
    </div>
  );
}

export default React.memo(PlantGrowth); // Memoize the PlantGrowth;
