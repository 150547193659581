import { useState, useEffect, useContext } from "react";
import CartCountContext from "./CartCountContext";
import axiosPrivate from './../../hooks/axiosPrivate';
import LoaderContext from "../Loader/LoaderContext";

const CartCountState = (props) => {
    const [cartCount, setCartCount] = useState(0);
    const [cartCountCheck, setCartCountCheck] = useState(false);
    const { setLoaderCheck } = useContext(LoaderContext);

    useEffect(() => {
        setCartCount(0); // Reset cart count immediately before API call

        let userCheck = JSON.parse(localStorage.getItem("userData"));
        let action = localStorage.getItem("action");

        if (userCheck && action === "login") {
            setLoaderCheck(true);

            axiosPrivate.get("carts/get")
                .then((res) => {
                    setLoaderCheck(false);
                    setCartCount(res?.data?.data?.length || 0); // Ensure 0 if empty response
                })
                .catch((error) => {
                    setLoaderCheck(false);
                    if (error.response?.status === 404) {
                        setCartCount(0); // Explicitly handle empty cart scenario
                    }
                });
        } else {
            const cartItems = JSON.parse(localStorage.getItem("cart_items"));
            setCartCount(cartItems?.length ?? 0);
        }
    }, [cartCountCheck]);

    return (
        <CartCountContext.Provider value={{ cartCount, setCartCount, cartCountCheck, setCartCountCheck }}>
            {props.children}
        </CartCountContext.Provider>
    );
}

export default CartCountState;
