import React, { useEffect, useState } from "react";
import styles from "./BlogPost.module.css";
import { Link } from "react-router-dom";
import { BsArrowRightCircle } from "react-icons/bs";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useLocation } from "react-router-dom";
import { GiWoodenClogs } from "react-icons/gi";
import moment from "moment";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { useContext } from "react";
import { showErrorToast } from "../../../Toster";

function BlogPost({ initialValues }) {
  const BlogPath = "/blog-listing";
  const location = useLocation();
  const [hide, setHide] = useState(location.pathname !== BlogPath);
  const [Blog, setBlog] = useState([]);

  const { setLoaderCheck } = useContext(LoaderContext);
  const [enquiryState, setEnquiryState] = useState([]);
  const latestThreeBlogs = Blog.slice(0, 3);
  const restOfTheBlogs = Blog.slice(3);

  useEffect(() => {
    if (location.pathname === BlogPath) {
      const mainContainer = document.querySelector("#mainContainer");
      mainContainer.style.marginTop = "0px";
    }
  }, [location.pathname]);

  const toggleViewMore = () => {
    setHide((prev) => !prev);
  };

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setLoaderCheck(true);
    axiosPrivate
      .get(`/blogs`, options)
      .then((blog) => {
        setLoaderCheck(false);
        setBlog(blog.data.data);
      })
      .catch((error) => {
        setLoaderCheck(false);
        if (error.response) {
          showErrorToast("Internal Server Error");
        }
      });
  }, []);

  function getFormattedDate(date) {
    const splitDate = moment(date).format("	LL").split(",");
    const publishedDate = splitDate[0].split(" ");
    return publishedDate[1];
  }

  function getFormattedMonth(date) {
    const splitDate = moment(date).format("	LL").split(",");
    const publishedDate = splitDate[0].split(" ");
    return publishedDate[0];
  }

  return (
    <div
      // style={{ display: "none" }}
      id="mainContainer"
      className={`px-0 mx-0 ${styles.mainContainer} ${
        location.pathname === BlogPath ? "blogMargin" : ""
      }`}
    >
      <img
        src="../assets/images/home/Group 244.webp"
        alt="Background"
        loading="lazy"
        className={styles.background}
      />

      <div className={styles.headingContainer}>
        <div className={styles.headingDiv}>
          <h2 className={styles.mainHeading}>
            {initialValues?.mainHeading}
            <img
              src="../assets/images/home/leaf-1-1 1.webp"
              alt="Leaf"
              loading="lazy"
              className={styles.leaf}
            />
          </h2>
        </div>
        <p className={styles.mainContent}>{initialValues?.mainContent}</p>
      </div>

      <div className={`container ${styles.blogs}`}>
        {latestThreeBlogs?.map((post, index) => {
          return index % 2 === 0 ? (
            <div className={styles.blogContainer}>
              <div className={styles.blogImg}>
                <img
                  className={styles.blogImg1}
                  src={
                    process.env.REACT_APP_COMMON_FILE_URL +
                    "blogs/" +
                    post?.featured_image
                  }
                  alt="Blog post"
                  loading="lazy"
                />
                <div className={styles.date}>
                  <h4 className={styles.postDate}>
                    {getFormattedDate(post?.publish_date)}
                  </h4>
                  <p className={styles.postMonth}>
                    {getFormattedMonth(post?.publish_date)}
                  </p>
                </div>
              </div>
              <div className={styles.blogContent}>
                <div>
                  <h3 className={styles.blogHeading}>
                    {post?.short_description.slice(0, 50)}
                  </h3>
                  <p className={styles.postContent}>
                    {post?.description.length > 350
                      ? post?.description.slice(0, 350) + "..."
                      : post?.description}
                  </p>
                  <Link
                    to={`/blog-detail/${post.id}`}
                    className={styles.readMoreBtn}
                  >
                    Read more
                  </Link>
                </div>
                <div className={styles.writer}>
                  <div className={styles.writerImage}>
                    <img
                      src="../favIcon.png"
                      alt="Writer name"
                      loading="lazy"
                    />
                  </div>
                  <h6 className={styles.writerName}>Minfert</h6>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`${styles.blogContainer} ${styles.responsiveBlogContainer}`}
            >
              <div className={styles.blogContent}>
                <div>
                  <h3 className={styles.blogHeading}>
                    {post?.short_description}
                  </h3>
                  <p className={styles.postContent}>{post?.description}</p>
                  <Link
                    to={`/blog-detail/${post.id}`}
                    className={styles.readMoreBtn}
                  >
                    Read more
                  </Link>
                </div>
                <div className={styles.writer}>
                  <div className={styles.writerImage}>
                    <img
                      src="../favIcon.png"
                      alt="Writer name"
                      loading="lazy"
                    />
                  </div>
                  <h6 className={styles.writerName}>Minfert</h6>
                </div>
              </div>

              <div className={styles.blogImg}>
                <img
                  className="img-fluid"
                  src={
                    process.env.REACT_APP_COMMON_FILE_URL +
                    "blogs/" +
                    post?.featured_image
                  }
                  alt="Blog post"
                  loading="lazy"
                />
                <div className={styles.date}>
                  <h4 className={styles.postDate}>
                    {getFormattedDate(post?.publish_date)}
                  </h4>
                  <p className={styles.postMonth}>
                    {getFormattedMonth(post?.publish_date)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}

        {!hide &&
          restOfTheBlogs?.map((post, index) => {
            return index % 2 === 0 ? (
              <div className={styles.blogContainer}>
                <div className={styles.blogImg}>
                  <img
                    src={
                      process.env.REACT_APP_COMMON_FILE_URL +
                      "blogs/" +
                      post?.featured_image
                    }
                    alt="Blog post"
                    loading="lazy"
                  />
                  <div className={styles.date}>
                    <h4 className={styles.postDate}>
                      {getFormattedDate(post?.publish_date)}
                    </h4>
                    <p className={styles.postMonth}>
                      {getFormattedMonth(post?.publish_date)}
                    </p>
                  </div>
                </div>
                <div className={styles.blogContent}>
                  <div>
                    <h3 className={styles.blogHeading}>
                      {post?.short_description}
                    </h3>
                    <p className={styles.postContent}>
                      {post?.description.length > 350
                        ? post?.description.slice(0, 350) + "..."
                        : post?.description}
                    </p>
                    <Link
                      to={`/blog-detail/${post.id}`}
                      className={styles.readMoreBtn}
                    >
                      Read more
                    </Link>
                  </div>
                  <div className={styles.writer}>
                    <div className={styles.writerImage}>
                      <img
                        src="../favIcon.png"
                        alt="Writer name"
                        loading="lazy"
                      />
                    </div>
                    <h6 className={styles.writerName}>Minfert</h6>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`${styles.blogContainer} ${styles.responsiveBlogContainer}`}
              >
                <div className={styles.blogContent}>
                  <div>
                    <h3 className={styles.blogHeading}>
                      {post?.short_description}
                    </h3>
                    <p className={styles.postContent}>{post?.description}</p>
                    <Link
                      to={`/blog-detail/${post.id}`}
                      className={styles.readMoreBtn}
                    >
                      Read more
                    </Link>
                  </div>
                  <div className={styles.writer}>
                    <div className={styles.writerImage}>
                      <img
                        src="../favIcon.png"
                        alt="Writer name"
                        loading="lazy"
                      />
                    </div>
                    <h6 className={styles.writerName}>Minfert</h6>
                  </div>
                </div>

                <div className={styles.blogImg}>
                  <img
                    src={
                      process.env.REACT_APP_COMMON_FILE_URL +
                      "blogs/" +
                      post?.featured_image
                    }
                    alt="Blog post"
                    loading="lazy"
                  />
                  <div className={styles.date}>
                    <h4 className={styles.postDate}>
                      {getFormattedDate(post?.publish_date)}
                    </h4>
                    <p className={styles.postMonth}>
                      {getFormattedMonth(post?.publish_date)}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {hide && (
        <div className={styles.viewButtonContainer}>
          <Link to="/blog-listing">
            <button
              className={`${styles.readMoreBtn} ${styles.viewMore}`}
              onClick={toggleViewMore}
            >
              {initialValues?.viewMoreBlogBtn}
              <BsArrowRightCircle className={styles.arrowBtn} />
            </button>
          </Link>
        </div>
      )}
      {hide && restOfTheBlogs > 0 && (
        <div className={styles.viewButtonContainer}>
          <Link to="/blog-listing">
            <button
              className={`${styles.readMoreBtn} ${styles.viewMore}`}
              onClick={toggleViewMore}
            >
              {initialValues?.viewMoreBlogBtn}
              <BsArrowRightCircle className={styles.arrowBtn} />
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default React.memo(BlogPost); // Memoize the BlogPost;
