import React, { useContext, useState, useEffect } from "react";
import BannerImage from "./BannerImage";
import PlantGrowth from "./PlantGrowth";
import Products from "./Products";
import CoreIdea from "./CoreIdea";
import Blogpost from "./BlogPost";
import NavigationSection from "./NavigationSection";
import ExplorePopup from "./ExplorePopup";
import LoaderContext from "../../../context/Loader/LoaderContext";

function Home(props) {
  const [isPopupOpen, setPopupOpen] = useState(false); // Start with false
  const { loaderCheck } = useContext(LoaderContext);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  // Ensure popup opens only after full website load + 5s delay
  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        setPopupOpen(true); // Open popup after 5s delay
      }, 3000); // 5000ms = 5 seconds
    };

    if (document.readyState === "complete") {
      // If already loaded, trigger the delay immediately
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div>
      <ExplorePopup isOpen={window.location.pathname.includes("home") && isPopupOpen && !loaderCheck} onClose={closePopup} />
      <BannerImage bannerValues={props.pageData.bannerValues} />
      <PlantGrowth plantValues={props.pageData.plantGrowthValues} />
      <NavigationSection initialValues={props.pageData.navigation} />
      <Products />
      <CoreIdea initialValues={props.pageData.coreValues} />
      <Blogpost initialValues={props.pageData.blogValues} />
    </div>
  );
}

export default React.memo(Home);
