import Piechart from "../common/piechart";
import Columnchart from "../common/columnchart";
import Donutchart from "../common/donutchart";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import MonthlyRevenueChart from "../common/stackedchart";
import { showErrorToast } from "../../../Toster";

const DashBoard = () => {
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [enquiry, setEnquiry] = useState([]);
  const [career, setCareer] = useState([]);
  const [audit, setAudit] = useState([]);


  const userData = JSON.parse(localStorage?.getItem("userData"));
  const { userName } = userData || "";

  useEffect(() => {
    axiosPrivate
      .get("users")
      .then((users) => {
        setUsers(users.data.data);
      })
      .catch((err) => {
        showErrorToast(err?.response?.data?.message ||err?.response?.data?.errors[0]?.msg)
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("contact-us")
      .then((enquiry) => {
        setEnquiry(enquiry?.data?.data);
      })
      .catch((err) => {
        showErrorToast(err?.response?.data?.message ||err?.response?.data?.errors[0]?.msg)
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("career")
      .then((career) => {
        setCareer(career.data.data);
      })
      .catch((err) => {
       
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("products")
      .then((product) => {
        setProducts(product.data.data);
      })
      .catch((err) => {
        showErrorToast(err?.response?.data?.message ||err?.response?.data?.errors[0]?.msg)
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("logs")
      .then((audit) => {
        setAudit(audit.data.data);
      })
      .catch((err) => {
        showErrorToast(err?.response?.data?.message ||err?.response?.data?.errors[0]?.msg)
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("orders")
      .then((order) => {
        setOrders(order.data.data);
      })
      .catch((err) => {
        showErrorToast(err?.response?.data?.message ||err?.response?.data?.errors[0]?.msg)
      });
  }, []);

  const amount = orders.reduce((totalAmount, order) => {
    return totalAmount + order.order_amount;
  }, 0);

  const usersCount = users.length;
  const productsCount = products.length;
  const ordersCount = orders.length;
  const enquiryCount = enquiry.filter((res)=>res?.form_type === "contact").length;
  const careerCount = career.length;
  const auditCount = audit.length;

  const sortedOrders = orders.sort(
    (a, b) => new Date(b.order_date) - new Date(a.order_date)
  );
  const latestOrders = sortedOrders.slice(0, 10);

  return (
    <div>
      <div className={`section-body marginTop} pt-3`}>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className={`section-body mb-4 pt-3}`}>
                <h4>Welcome {userName}!</h4>
                <small>Effortless management, boundless possibilities. </small>
              </div>
            </div>
          </div>
          <div className="row clearfix ">
            <div className="col-6 col-md-4 col-xl-2">
              <div className="card">
                <div className="card-body ribbon">
                  <div className="ribbon-box orange">{usersCount}</div>
                  <Link to="/admin/users" className="my_sort_cut text-muted">
                    <i className="icon-users" />
                    <span>Users</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-2">
              <div className="card">
                <div className="card-body ribbon">
                  <div className="ribbon-box orange">{productsCount}</div>
                  <Link to="/admin/products" className="my_sort_cut text-muted">
                    <i className="icon-present" />
                    <span>Products</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-2">
              <div className="card">
                <div className="card-body ribbon">
                  <div className="ribbon-box orange">{ordersCount}</div>
                  <Link to="/admin/orders" className="my_sort_cut text-muted">
                    <i className="icon-wallet" />
                    <span>Orders</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-2">
              <div className="card">
                <div className="card-body ribbon">
                <div className="ribbon-box orange">{enquiryCount}</div>
                  <Link to="/admin/enquiry" className="my_sort_cut text-muted">
                    <i className="icon-target" />
                    <span>Enquiries</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-2">
              <div className="card">
              <div className="card-body ribbon">
                <div className="ribbon-box orange">{careerCount}</div>
                  <Link to="/admin/career" className="my_sort_cut text-muted">
                    <i className="icon-user-follow" />
                    <span>Career</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-2">
              <div className="card">
              <div className="card-body ribbon">
                <div className="ribbon-box orange">{auditCount}</div>
                  <Link
                    to="/admin/audit-logs"
                    className="my_sort_cut text-muted"
                  >
                    <i className="icon-pie-chart" />
                    <span>Audit Logs</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body pt-3">
        <div className="container-fluid">
          <div className="row clearfix row-deck">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Revenue</h3>
                  {/* <div className="card-options">
                    <label className="custom-switch m-0">
                      <input
                        type="checkbox"
                        defaultValue={1}
                        className="custom-switch-input"
                        defaultChecked
                      />
                      <span className="custom-switch-indicator" />
                    </label>
                  </div> */}
                </div>
                <div className="card-body">
                  <MonthlyRevenueChart data={orders} />
                </div>
                <div className="card-footer">
                  <div className="d-flex justify-content-between align-items-center">
                    <a
                      href="/admin/orders"
                      className="btn btn-success btn-sm w200 mr-3"
                    >
                      View Details
                    </a>
                    <small>
                      Measure How Fast You’re Growing Monthly Recurring Revenue.{" "}
                     
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Orders</h3>
                </div>
                <small className="m-3 pl-1">
                  Where possibilities meet purpose. <br />{" "}
                  {/* <a href="fake_url">Read more</a>{' '} */}
                </small>
                <Piechart orders={orders}></Piechart>
                <div className="card-body text-center">
                  <div className="mt-4"></div>
                </div>
                <div className="card-footer">
                  <a
                    href="/admin/orders"
                    className="btn btn-block btn-success btn-sm"
                  >
                    View More
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-lg-6 col-md-6" >
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">My Balance</h3>
                </div>
                <div className="card-body">
                  <span>Balance</span>
                  <h4>
                    $<span className="counter">20,508</span>
                  </h4>
                  <Sparklineschart className="mb-4"></Sparklineschart>

                  <div className="form-group">
                    <label className="d-block">
                      Bank of America{" "}
                      <span className="float-right">
                        $<span className="counter">15,025</span>
                      </span>
                    </label>
                    <div className="progress progress-xs">
                      <div
                        className="progress-bar bg-azure"
                        role="progressbar"
                        aria-valuenow={77}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "77%" }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="d-block">
                      RBC Bank{" "}
                      <span className="float-right">
                        $<span className="counter">1,843</span>
                      </span>
                    </label>
                    <div className="progress progress-xs">
                      <div
                        className="progress-bar bg-green"
                        role="progressbar"
                        aria-valuenow={50}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "50%" }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="d-block">
                      Frost Bank{" "}
                      <span className="float-right">
                        $<span className="counter">3,641</span>
                      </span>
                    </label>
                    <div className="progress progress-xs">
                      <div
                        className="progress-bar bg-blue"
                        role="progressbar"
                        aria-valuenow={23}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "23%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <a href="fake_url" className="btn btn-block btn-info btn-sm">
                    View More
                  </a>
                </div>
              </div>
            </div> */}
          </div>
          <div
            className="row clearfix row-deck pt-3"
            style={{ display: "none" }}
          >
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Employee Structure</h3>
                </div>
                <div className="card-body text-center">
                  <Columnchart></Columnchart>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Performance</h3>
                </div>
                <div className="card-body">
                  <span>
                    Measure How Fast You’re Growing Monthly Recurring Revenue.{" "}
                    <a href="fake_url">Learn More</a>
                  </span>
                  <ul className="list-group mt-3 mb-0">
                    <li className="list-group-item">
                      <div className="clearfix">
                        <div className="float-left">
                          <strong>35%</strong>
                        </div>
                        <div className="float-right">
                          <small className="text-muted">Design Team</small>
                        </div>
                      </div>
                      <div className="progress progress-xs">
                        <div
                          className="progress-bar bg-azure"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow={42}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="clearfix">
                        <div className="float-left">
                          <strong>25%</strong>
                        </div>
                        <div className="float-right">
                          <small className="text-muted">Developer Team</small>
                        </div>
                      </div>
                      <div className="progress progress-xs">
                        <div
                          className="progress-bar bg-green"
                          role="progressbar"
                          style={{ width: "25%" }}
                          aria-valuenow={0}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="clearfix">
                        <div className="float-left">
                          <strong>15%</strong>
                        </div>
                        <div className="float-right">
                          <small className="text-muted">Marketing</small>
                        </div>
                      </div>
                      <div className="progress progress-xs">
                        <div
                          className="progress-bar bg-orange"
                          role="progressbar"
                          style={{ width: "15%" }}
                          aria-valuenow={36}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="clearfix">
                        <div className="float-left">
                          <strong>20%</strong>
                        </div>
                        <div className="float-right">
                          <small className="text-muted">Management</small>
                        </div>
                      </div>
                      <div className="progress progress-xs">
                        <div
                          className="progress-bar bg-indigo"
                          role="progressbar"
                          style={{ width: "20%" }}
                          aria-valuenow={6}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="clearfix">
                        <div className="float-left">
                          <strong>11%</strong>
                        </div>
                        <div className="float-right">
                          <small className="text-muted">Other</small>
                        </div>
                      </div>
                      <div className="progress progress-xs">
                        <div
                          className="progress-bar bg-pink"
                          role="progressbar"
                          style={{ width: "11%" }}
                          aria-valuenow={6}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Growth</h3>
                </div>
                <Donutchart />
              </div>
            </div>
          </div>
          <div className="row clearfix pt-3">
            <div className="col-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title" style={{fontWeight:"bold"}}>Order Summary</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                      <thead>
                        <tr>
                          <th>Order Id</th>
                          <th>Customer Name</th>
                          <th>Shipping City</th>
                          <th>Order Date</th>
                          <th>Order Amount</th>
                          {/* <th>Payment</th> */}
                          <th>Order Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {latestOrders.map((item) => (
                          <tr>
                            <td>{item.order_id}</td>
                            <td>{item.bill_name || item.ship_name}</td>
                            <td>{item.bill_city || item.ship_city}</td>
                            <td>
                              {new Date(item.order_date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                            <td>{item.order_amount}</td>
                            {/* <td>{item.payment_status}</td> */}
                            <td>
                              {item.order_status === "cancel" && (
                                <span className="btn text-white" style={{background:"red",minWidth:"105px"}}>Cancelled</span>
                              )}
                               {item.order_status === "cancelled" && (
                                <span className="btn text-white" style={{background:"red",minWidth:"105px"}}>Cancelled</span>
                              )}
                              {item.order_status === "pending" && (
                                <span className="btn text-white" style={{background:"orange",minWidth:"105px"}}>Pending</span>
                              )}
                              {item.order_status === "shipped" && (
                                <span className="btn text-white" style={{background:"brown",minWidth:"105px"}}>Shipped</span>
                              )}
                              {item.order_status === "confirmed" && (
                                <span className="btn text-white" style={{background:"green",minWidth:"105px"}}>Completed</span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
