import { useContext, useEffect } from "react";
import LoaderContext from "./LoaderContext";
import "../../../src/assets/css/loader.css";
import loaderGif from "../../assets/images/loader.gif";

const Loader = () => {
    const { loaderCheck } = useContext(LoaderContext);

    // ✅ Disable scrolling when loader is active
    useEffect(() => {
        document.body.style.overflow = loaderCheck ? "hidden" : "auto";
        return () => {
            document.body.style.overflow = "auto"; // Cleanup
        };
    }, [loaderCheck]);

    return (
        <>
            {loaderCheck && (
                <div className="loader-container">
                    <img src={loaderGif} alt="Loading..." className="loader-gif" />
                </div>
            )}
        </>
    );
};

export default Loader;
