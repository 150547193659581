import React from "react";
import styles from "./FooterBar.module.css";
import { Link } from "react-router-dom";

function FooterBar() {
  const currentYear = new Date().getFullYear();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use 'auto' for instant scrolling
    });
  };
  return (
    <div className={styles.footerBar}>
      <div className="d-flex gap-1 flex-column flex-md-row">
        <Link to="/">
          <p className={styles.minfert}>
            Minfert © {currentYear}. All Rights Reserved{" "}
          </p>
        </Link>
        <div className="d-flex">
          <Link to="/privacy-policy" className={styles.minfert}>
            <p> | Privacy Policy</p>
          </Link>
          <Link to="/cookie-policy" className={styles.minfert}>
            <p> | Cookie Policy</p>
          </Link>
          <Link to="/terms&conditions" className={styles.minfert}>
            <p> | Terms & Conditions</p>
          </Link>
        </div>
      </div>
      <Link to="https://webanixsolutions.com/" target="_blank">
        <p className={styles.navItems} onClick={scrollToTop}>
          Designed by WEBaniX Pvt Ltd
        </p>
      </Link>

      {/* <ul className={styles.menus}>
        
      </ul> */}
    </div>
  );
}

export default React.memo(FooterBar); // Memoize the FooterBar
